import React from "react";
import { Link } from "react-router-dom";

const MachinePreview = ({ machine, minimal = false, sx }) => (
  <div
    key={machine.id}
    className={`bg-white p-4 border ${sx ? sx : ""}`.trim()}
  >
    <div className={minimal ? "" : `min-h-[300px]`}>
      <Link
        to={`/machines/${machine.id}`}
        data-testid={`machine-${machine.id}-preview-link`}
      >
        <img
          src={machine.images[0]}
          alt={machine.title}
          className="w-full h-48 object-cover mb-4"
        />
        <h3 className={`text-lg font-bold`}>{machine.title}</h3>
      </Link>
      <p className="text-gray-500 mb-3">{machine.category}</p>
    </div>
    {!!machine.price && (
      <h3 className="text-xl font-semibold mt-2 mb-4">
        {new Intl.NumberFormat("sv-SE", {
          style: "currency",
          currency: machine.currency || "SEK",
        }).format(machine.price)}
      </h3>
    )}
    {!minimal && (
      <Link to={`/machines/${machine.id}/quotation`}>
        <button className="bg-primary text-white py-2 px-4 hover:bg-orange-600 transition duration-300 ease-in-out">
          Begär offert
        </button>
      </Link>
    )}
    <hr className="my-2 mt-5 mb-5" />
    <h5 className="text-md font-bold mb-2">Kortfattade specifikationer</h5>
    <ul className="text-sm text-gray-500 list-disc ml-4">
      <li>
        Artikelnummer: <strong>#{machine.id}</strong>
      </li>
      <li>
        Skick: <strong>{machine.condition}</strong>
      </li>
      <li>
        Tillgänglig: <strong>Ja</strong>
      </li>
      {machine.buildYear && (
        <li>
          Årsmodell: <strong>{machine.buildYear}</strong>
        </li>
      )}
    </ul>
  </div>
);

export default MachinePreview;
